import React, { FC } from 'react';
import { LayoutProps } from 'styled-system';
import { Overlay } from './blur-overlay.styled';

type VariantOptions =
  | 'full'
  | 'left'
  | 'bottom'
  | 'leftAndBottom'
  | 'solid'
  | null;

export type BlurOverlayProps = {
  zIndex?: number;
  blur?: number;
  variant?: VariantOptions | VariantOptions[];
} & LayoutProps;

const BlurOverlay: FC<BlurOverlayProps> = ({
  zIndex,
  blur,
  variant,
  ...props
}) => {
  return <Overlay zIndex={zIndex} blur={blur} variant={variant} {...props} />;
};

BlurOverlay.defaultProps = {
  zIndex: 5,
  blur: 40,
  variant: 'full',
};

export default BlurOverlay;
