import { useMotionValue, useSpring } from 'framer-motion';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Grid } from './video-grid.styled';

type VideoGridProps = {
  isMobilePortrait?: boolean;
};

const VideoGrid: FC<VideoGridProps> = ({
  children,
  isMobilePortrait = false,
}) => {
  const [videoCount, setVideoCount] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const [gridItemsWidth, setGridItemsWidth] = useState(0);

  const gridRef = useRef<HTMLDivElement>(null);
  const x = useMotionValue(0);
  const dampedX = useSpring(x, { damping: 40 });

  useEffect(() => {
    if (gridRef.current && children) {
      setScreenWidth(
        gridRef.current.clientWidth || document.documentElement.clientWidth
      );
      setGridItemsWidth(gridRef.current.scrollWidth);
      setVideoCount(gridRef.current.children.length);
    }
  }, [children]);

  useEffect(() => {
    if (isMobilePortrait) {
      dampedX.set(0);
    }
  }, [isMobilePortrait, dampedX]);

  const dragEnd = () => {
    setTimeout(() => {
      if (gridRef.current) {
        const computedStyle = window.getComputedStyle(gridRef.current);
        const xPos = new DOMMatrix(computedStyle.transform).m41;

        // Hit the left side boundary
        if (xPos > 0) {
          dampedX.set(0);
        }

        // Hit the right side boundary
        if (screenWidth - gridItemsWidth > xPos) {
          dampedX.set(
            screenWidth -
              (gridItemsWidth + parseFloat(computedStyle.paddingRight))
          );
        }
      }
    }, 200);
  };

  return (
    <Grid
      initial={{ opacity: 0, y: 24 }}
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay: 0.5, ease: 'easeInOut', duration: 0.5 },
      }}
      exit={{ opacity: 0, y: 24 }}
      transition={{ ease: 'easeInOut', duration: 0.5 }}
      videoCount={videoCount}
      ref={gridRef}
      style={{ x: dampedX }}
      drag={!isMobilePortrait && screenWidth < gridItemsWidth ? 'x' : false}
      onDragEnd={dragEnd}
      dragMomentum={false}
    >
      {children}
    </Grid>
  );
};

export default VideoGrid;
