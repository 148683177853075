import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled from 'styled-components';
import { layout, variant } from 'styled-system';
import { BlurOverlayProps } from './blur-overlay';

export const Overlay = styled(motion.div).attrs<BlurOverlayProps>(
  ({ blur }) => ({
    initial: { '--blur': '0px' },
    animate: { '--blur': `${blur}px` },
    exit: { '--blur': '0px' },
    transition: { duration: 0.5 },
  })
)<BlurOverlayProps>`
  ${({ theme }) => `
    --bg-color: ${rgba(theme.colors.background, 0.6)};
    --bg-color-solid: ${theme.colors.background};
  `}

  position: absolute;
  top: 0;
  left: 0;
  z-index: ${({ zIndex }) => zIndex};
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  ${variant({
    variants: {
      full: {
        backdropFilter: `blur(var(--blur))`,
        backgroundImage: `radial-gradient(var(--bg-color), transparent)`,
      },
      left: {
        maskImage: `linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 40%,
          rgba(0, 0, 0, 0.5) 80%,
          transparent
        )`,
        backgroundImage: `linear-gradient(to right, var(--bg-color-solid) 20%, transparent 60%), linear-gradient(to bottom, var(--bg-color-solid) 5%, transparent 80%)`,
      },
      bottom: {
        maskImage: `linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 0.5) 40%,
          transparent
        )`,
        backgroundImage: `linear-gradient(to top, var(--bg-color-solid) 5%, transparent 80%)`,
      },
      leftAndBottom: {
        maskImage: `linear-gradient(
          to right,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 0.5) 50%,
          transparent
        ), linear-gradient(
          to top,
          rgba(0, 0, 0, 1) 20%,
          rgba(0, 0, 0, 0.5) 40%,
          transparent
        )`,
        backgroundImage: `linear-gradient(to right, var(--bg-color) 20%, transparent 60%), linear-gradient(to bottom, var(--bg-color-solid) 5%, transparent 80%), linear-gradient(to top, var(--bg-color-solid) 5%, transparent 80%)`,
      },
      solid: {
        background: `var(--bg-color-solid)`,
      },
    },
  })}

  ${layout}
`;
