import styled, { css } from 'styled-components';

type VideoProps = {
  hideBigButton?: boolean;
};

export const StyledVideo = styled.div<VideoProps>`
  ${({ theme, hideBigButton = true }) => {
    return css`
      --plyr-control-icon-size: 24px;
      --plyr-color-main: ${theme.colors.yellow};
      --plyr-video-controls-background: rgba(0, 0, 0, 0.7);
      --plyr-control-spacing: 12px;
      --plyr-font-size-large: 3rem;

      .plyr__control--overlaid {
        padding: var(--plyr-control-spacing);
        visibility: ${hideBigButton ? 'hidden' : 'initial'};
      }

      ${({ theme }) => theme.mediaQueries.large} {
        --plyr-control-spacing: 18px;
      }
    `;
  }}

  width: 100%;
  height: 100vh;

  .plyr__controls {
    height: 60px;
    ${({ theme }) => theme.mediaQueries.large} {
      height: 90px;
    }

    padding-top: var(--plyr-control-spacing);
  }
`;

type OverlayProps = {
  hideOverlay: boolean;
};

export const OverlayControl = styled.div<OverlayProps>`
  ${(props) => {
    const zIndex = props.hideOverlay ? '-1' : '9';

    return css`
      z-index: ${zIndex};
    `;
  }}

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 60px);

  ${({ theme }) => theme.mediaQueries.large} {
    height: calc(100% - 90px);
  }
`;
