import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export type NumberCircleStyled = {
  size: string;
};

export const NumberCircleStyled = styled.svg<NumberCircleStyled>`
  ${(props) => {
    const iconSize = props.size === 'large' ? '80px' : '50px';

    return css`
      width: ${iconSize};
      height: ${iconSize};
    `;
  }}

  stroke: ${({ theme }) => theme.colors.text};

  text {
      font-size: ${({ theme }) => theme.fontSize.xSmall};
  }
`;

export const AnimateCircle = styled(motion.circle).attrs((props) => ({
  initial: { strokeDasharray: '100 0' },
  animate: { strokeDasharray: '0 100' },
  transition: { duration: props.dur, ease: 'linear' },
}))`
  stroke-dashoffset: 25;
  stroke-width: 3px;
`;
