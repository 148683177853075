/**
 * Shared API utilities between the front end and mock data script
 * */

import { getSdk, MultimediaQuery } from './graphql';

// Update Multimedia object by adding in journey data using a separate query
export const addJourneyData = async (
  data: MultimediaQuery,
  sdk: ReturnType<typeof getSdk>
) => {
  let newData = { ...data };

  await new Promise((resolve, reject) => {
    newData.multimedia?.entities?.forEach((entity) => {
      if (entity?.__typename === 'NodeMultimediaBrief') {
        entity.fieldExperience?.forEach(async (experience) => {
          if (
            experience?.entity?.__typename === 'NodeExperience' &&
            experience.entity.fieldJourneys
          ) {
            try {
              const journeyQueries = await Promise.all(
                experience.entity.fieldJourneys?.map((journey) =>
                  sdk.Journey({
                    id: [`${journey?.targetId}`],
                  })
                )
              );

              const journeys = experience.entity.fieldJourneys.map(
                (journey, index) => {
                  if (journey?.targetId) {
                    return {
                      ...journey,
                      [journey.targetId]: {
                        ...journeyQueries[index].nodeQuery?.journey?.[0],
                      },
                    };
                  } else {
                    return journey;
                  }
                }
              );

              experience.entity.fieldJourneys = journeys;
              resolve();
            } catch (error) {
              reject(error);
            }
          }
        });
      }
    });
  });

  return newData;
};
