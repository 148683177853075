import { setCSSUnit } from 'utils/helpers';

export const fontSizes = [0.9, 1, 1.5, 2.25, 3.375, 5.063];

export const fontSize = {
  xSmall: setCSSUnit(fontSizes[0], 'rem'),
  small: setCSSUnit(fontSizes[1], 'rem'),
  medium: setCSSUnit(fontSizes[2], 'rem'),
  large: setCSSUnit(fontSizes[3], 'rem'),
  xLarge: setCSSUnit(fontSizes[4], 'rem'),
  xxLarge: setCSSUnit(fontSizes[5], 'rem'),
};
