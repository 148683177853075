import { setCSSUnit } from 'utils/helpers';

export const space = [0, 4, 8, 16, 32, 64, 128];

export const spaces = {
  xSmall: setCSSUnit(space[1]),
  small: setCSSUnit(space[2]),
  medium: setCSSUnit(space[3]),
  large: setCSSUnit(space[4]),
  xLarge: setCSSUnit(space[5]),
  xxLarge: setCSSUnit(space[6]),
};
