import { rgba } from 'polished';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.spaces.medium};
  overflow: hidden;
  text-align: center;
  background-color: ${({ theme }) => rgba(theme.colors.background, 0.95)};
  backdrop-filter: blur(8px);

  > * + * {
    margin-top: ${({ theme }) => theme.spaces.large};
  }
`;
