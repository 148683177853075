export const SETTINGS = {
  apiEndpoint:
    (window as AppWindow).apiEndpoint || process.env.REACT_APP_API_ENDPOINT,
  graphqlEndpoint:
    (window as AppWindow).apiEndpoint || process.env.REACT_APP_GRAPHQL_ENDPOINT,
  analyticsEndpoint:
    (window as AppWindow).analyticsEndpoint ||
    process.env.REACT_APP_ANALYTICS_ENDPOINT,
  appMode:
    (window as AppWindow).appMode || process.env.REACT_APP_MODE || 'kiosk',
  appTitle: process.env.REACT_APP_NAME,
  airbrake: {
    projectId: Number(process.env.REACT_APP_AIRBRAKE_PROJECTID) || 0,
    projectKey: process.env.REACT_APP_AIRBRAKE_PROJECTKEY || '',
    enabled: process.env.REACT_APP_AIRBRAKE_ENABLED === 'true' ? true : false,
  },
  autoplayTimeout: 20,
  idleTimeout: 10000,
  tapToPause: false,
};

export const DATE_FORMAT = {
  /** `dd/MM/yyyy` */
  long: 'dd/MM/yyyy',
  /** `d/M/yy` */
  short: 'd/M/yy',
};

export const DATETIME_FORMAT = {
  /** `dd/MM/yyyy HH:mm:ss` */
  long: 'dd/MM/yyyy HH:mm:ss',
  /** `dd/MM/yyyy HH:mm` */
  medium: 'dd/MM/yyyy HH:mm',
  /** `d/M/yy H:mm` */
  short: 'd/M/yy H:mm',
};
