import { GraphQLClient } from 'graphql-request';
import { SETTINGS } from 'settings/config';
import { fetchData, __DEV__ } from 'utils/helpers';
import { addJourneyData } from './common';
import { getSdk, MultimediaQuery } from './graphql';

type APIData = {
  en: MultimediaQuery;
};

// Helper to extract the main multimedia data
export type MultimediaData = ReturnType<typeof getMultimediaData>;
const getMultimediaData = (data: MultimediaQuery) => {
  const multimedia = data.multimedia?.entities?.[0];

  if (multimedia?.__typename === 'NodeMultimediaBrief') {
    return multimedia;
  } else {
    return null;
  }
};

// Get experience from remote GraphQL endpoint when in "preview" mode
const getPreviewExperience = async (id: string) => {
  const client = new GraphQLClient(SETTINGS.graphqlEndpoint || '', {
    headers: {
      authorization: `Basic ${process.env.REACT_APP_ECMS_CREDENTIALS}`,
    },
  });
  const sdk = getSdk(client);

  try {
    const data = await sdk.Multimedia({ id: [id] });
    const updatedData = await addJourneyData(data, sdk);
    return updatedData;
  } catch (e) {
    throw Error(e);
  }
};

// Fetch and return the experience data
export const getExperience = async (key: string, id?: string) => {
  let path = __DEV__ ? 'mock' : 'wam/assets';

  // player being access externally
  if (!__DEV__ && SETTINGS.appMode === 'public' && id) {
    path = id;
  }
  const fileName = __DEV__
    ? localStorage.getItem('mockFileName') || 'single'
    : 'data';

  try {
    const data =
      id && SETTINGS.appMode === 'kiosk'
        ? await getPreviewExperience(id)
        : (
            await fetchData<APIData>(
              `${SETTINGS.apiEndpoint}/${path}/${fileName}.json` || ''
            )
          ).en;

    return getMultimediaData(data);
  } catch (e) {
    throw Error(e);
  }
};
