import sprite from 'icons/plyr.svg';
import React, { FC } from 'react';
import { SVG } from './icon.styled';

export enum IconType {
  'help-icon',
  'close-icon',
  'back-icon',
  'plyr-play',
  'plyr-restart',
  'smart-phone',
}

export type IconProps = {
  type: keyof typeof IconType;
};

const Icon: FC<IconProps> = ({ type }) => {
  return (
    <SVG>
      <use href={`${sprite}#${type}`}></use>
    </SVG>
  );
};

export default Icon;
