import { sendAnalyticsData } from 'api/analytics';
import { GlobalStyles } from 'app.styled';
import MockDataSwitch from 'components/mock-data-switch';
import { useExperience } from 'hooks/use-experience';
import Root from 'pages';
import React, { FC, useMemo } from 'react';
import track from 'react-tracking';
import { theme } from 'settings/styles/theme';
import { ThemeProvider } from 'styled-components';

const AppBase: FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Root />
      <MockDataSwitch />
    </ThemeProvider>
  );
};

function App() {
  const { data } = useExperience();
  const galleryName =
    (data?.fieldGallery?.entity?.__typename === 'NodeGallery' &&
      data.fieldGallery.entity.title) ||
    '';
  const multimediaId = data?.entityId || '';

  // Add in base analytics data and method for sending data
  const WithAnalytics = useMemo(
    () =>
      track(
        { gallery: galleryName, multimediaId: multimediaId },
        {
          dispatch: (data) => sendAnalyticsData(data),
        }
      )(AppBase),
    [multimediaId, galleryName]
  );

  return <WithAnalytics />;
}

export default App;
