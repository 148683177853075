import { Wrapper } from 'components/action-message/action-message.styled';
import BlurOverlay from 'components/blur-overlay';
import Heading from 'components/heading';
import Icon from 'components/icon';
import React from 'react';
import { AnimatedIcon } from './rotate-screen-message.styled';

const RotateScreenMessage: React.FC = () => {
  return (
    <>
      <BlurOverlay zIndex={1} variant={'solid'}></BlurOverlay>
      <Wrapper>
        <AnimatedIcon>
          <Icon type='smart-phone' />
        </AnimatedIcon>
        <Heading type='h4' mt={3}>
          Rotate to view full screen
        </Heading>
      </Wrapper>
    </>
  );
};

export default RotateScreenMessage;
