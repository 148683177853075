import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledMain = styled(motion.main).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}))`
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.background};
  transition: background-color ${({ theme }) => theme.transitions.ease};
`;
