import styled, { css } from 'styled-components';
import { HeroLayoutProps } from './hero-layout';

export const LayoutWrapper = styled.div<HeroLayoutProps>`
  ${(props) => {
    let layoutCoverage = '100vh';
    const layoutPosition = props.overlay === true ? 'absolute' : 'relative';

    if (props.mode === 'multiple') {
      layoutCoverage = `calc(100vh - ${props.gridHeight}px)`;
    }

    return css`
      --hero-offset: 40vh;
      --hero-background-offset: calc(
        var(--hero-offset) + ${({ theme }) => theme.spaces.large}
      );
      --padding-x: ${({ theme }) => theme.spaces.large};

      position: ${layoutPosition};
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding-top: var(--hero-offset);
      padding-right: var(--padding-x);
      padding-left: var(--padding-x);

      @media (orientation: landscape) {
        min-height: ${layoutCoverage};
      }

      ${({ theme }) => theme.mediaQueries.small} {
        --hero-offset: 84px;
        --padding-x: ${({ theme }) => theme.spaces.xLarge};

        min-height: ${layoutCoverage};
      }

      ${({ theme }) => theme.mediaQueries.medium} {
        --hero-offset: ${({ theme }) => theme.spaces.large};
      }

      ${({ theme }) => theme.mediaQueries.large} {
        --hero-offset: 104px;
        --padding-x: ${({ theme }) => theme.spaces.xxLarge};
      }
    `;
  }}
`;
