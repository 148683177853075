import { logger } from '@hatchd/utils';
import { SETTINGS } from 'settings/config';
import { fetchData, __DEV__ } from 'utils/helpers';

export const sendAnalyticsData = (data: {} = {}) => {
  if (__DEV__) {
    logger.info('Analytics Event Dispatched', data);
    return;
  }

  return fetchData(SETTINGS.analyticsEndpoint || '', {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
    },
  })
    .then((data) => logger.info('Sent Analytics Event', data))
    .catch((error) => logger.error(error));
};
