import ErrorBoundary from 'components/error-boundary';
import { AnimatePresence } from 'framer-motion';
import React, { FC, useState } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Landing from './landing';
import NotFound from './not-found';
import Watch from './watch';

const Root: FC = () => {
  // Keep track of sequential automatic video plays
  const [playCount, setPlayCount] = useState(0);

  return (
    <ErrorBoundary>
      <Router>
        <Route
          render={({ location }) => (
            <AnimatePresence exitBeforeEnter initial={false}>
              <Switch location={location} key={location.pathname}>
                <Route
                  exact
                  path='/'
                  render={() => <Landing setPlayCount={setPlayCount} />}
                />
                <Route
                  exact
                  path='/watch/:id'
                  render={() => (
                    <Watch playCount={playCount} setPlayCount={setPlayCount} />
                  )}
                />
                <Route path='*' component={NotFound} />
              </Switch>
            </AnimatePresence>
          )}
        />
      </Router>
    </ErrorBoundary>
  );
};

export default Root;
