import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type GridProps = {
  videoCount: number;
};

export const Grid = styled(motion.div)<GridProps>`
  ${(props) => {
    return css`
      position: relative;
      z-index: 1;
      display: grid;
      grid-auto-flow: row;
      gap: ${({ theme }) => theme.spaces.large};
      padding: ${({ theme }) => theme.spaces.large};

      ${({ theme }) => theme.mediaQueries.medium} {
        grid-auto-flow: column;
        grid-template-columns: repeat(${props.videoCount}, 200px);
        height: 250px;
        padding: 0 ${({ theme }) => theme.spaces.xLarge};
      }

      ${({ theme }) => theme.mediaQueries.large} {
        padding: 0 ${({ theme }) => theme.spaces.xxLarge};
      }
    `;
  }}
`;
