import styled from 'styled-components';
import { space } from 'styled-system';
import { Button } from './button';

export const ButtonStyled = styled.button<Button>`
  ${({ theme, variant }) => `
    --border-color: ${
      variant === 'outline' ? theme.colors.text : theme.colors.yellow
    };
    --bg-color: ${variant === 'outline' ? 'transparent' : theme.colors.yellow};
    --txt-color: ${
      variant === 'outline' ? theme.colors.text : theme.colors.black
    };
  `}

  --height: 48px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: var(--height);
  padding: 0 ${({ theme }) => theme.spaces.medium};
  color: var(--txt-color);
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.small};
  text-transform: uppercase;
  text-decoration: none;
  background-color: var(--bg-color);
  border: 2px solid var(--border-color);
  border-radius: var(--height);

  ${({ theme }) => theme.mediaQueries.large} {
    --height: 72px;

    min-width: 300px;
    padding: 0 ${({ theme }) => theme.spaces.large};
    font-size: ${({ theme }) => theme.fontSize.medium};
  }

  /* Styled System Overrides */
  ${space}

  svg {
    width: 20px;
    height: 20px;
    margin-right: ${({ theme }) => theme.spaces.small};

    ${({ theme }) => theme.mediaQueries.large} {
      width: 24px;
      height: 24px;
      margin-right: ${({ theme }) => theme.spaces.medium};
    }
  }
`;
