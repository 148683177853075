import React from 'react';
import { queryCache } from 'react-query';
import { __DEV__ } from 'utils/helpers';
import { Select, Wrapper } from './mock-data-switch.styled';

const data = ['single', 'multiple-lte-8', 'multiple-gte-8'];
const key = 'mockFileName';
const initialItem = localStorage.getItem(key);

type Props = {
  enabled?: boolean;
};

const MockDataSwitch: React.FC<Props> = ({ enabled }) => {
  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    localStorage.setItem(key, event.target.value);
    queryCache.invalidateQueries('experience');
  };

  if (enabled) {
    return (
      <Wrapper>
        <Select onChange={handleSelect} defaultValue={initialItem || ''}>
          {data.map((item) => (
            <option value={item} key={item}>
              Mock data: {item}
            </option>
          ))}
        </Select>
      </Wrapper>
    );
  }
  return null;
};

MockDataSwitch.defaultProps = {
  enabled: __DEV__,
};

export default MockDataSwitch;
