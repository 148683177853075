import { useMediaQuery } from '@react-hook/media-query';
import ActionMessage from 'components/action-message';
import BlurOverlay from 'components/blur-overlay';
import Button from 'components/button';
import ContentBlock from 'components/content-block';
import ErrorMessage from 'components/error-message';
import HeroLayout from 'components/hero-layout';
import Icon from 'components/icon';
import Loader from 'components/loader';
import Main from 'components/main';
import NextVideo from 'components/next-video';
import RotateScreenMessage from 'components/rotate-screen-message';
import SEO from 'components/seo';
import ThemeToggle from 'components/theme-toggle';
import TitleBar from 'components/title-bar';
import Video from 'components/video';
import VideoTitle from 'components/video-title';
import { AnimatePresence } from 'framer-motion';
import { useExperience } from 'hooks/use-experience';
import { useIdle } from 'hooks/use-idle';
import Plyr from 'plyr';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { breakpoints } from 'settings/styles';
import { browser, getDeviceOrientation, getFullVideoData } from 'utils/helpers';

type Params = {
  id?: string;
};

export type WatchProps = {
  playCount: number;
  setPlayCount: Dispatch<SetStateAction<number>>;
};

const Watch: FC<WatchProps> = ({ playCount, setPlayCount }) => {
  const [showNextVideo, setShowNextVideo] = useState(false);
  const [showTitleBar, setShowTitleBar] = useState(false);
  const [showReplay, setShowReplay] = useState(false);
  const [showIdleMessage, setShowIdleMessage] = useState(false);
  const [autoplay, setAutoplay] = useState(true);
  const [helpOpen, setHelpOpen] = useState(false);
  const [inMobilePortrait, setInMobilePortrait] = useState(
    getDeviceOrientation().indexOf('portrait') >= 0
  );
  const plyrInstance = useRef<Plyr>();

  const history = useHistory();
  const homePath = '/' + history.location.search;

  // Params passed via the URL
  let { id } = useParams<Params>();

  // Tracking
  const { trackEvent } = useTracking();

  // Keep track of user activity
  const isIdle = useIdle();

  // Main Experience Data
  const { data, error, isLoading, isError } = useExperience();

  // Data specific to this video
  const videoData = useMemo(() => {
    if (data && id) {
      return getFullVideoData(Number(id), data);
    }
    return null;
  }, [data, id]);

  const videoTitle = videoData?.video?.videoTitle || '';
  const videoIndex = videoData?.currentVideoIdx || 0;

  const isSmallScreen = useMediaQuery(
    `screen and (max-width: ${breakpoints[2]})`
  );

  const isSmallScreenPortrait =
    isSmallScreen && getDeviceOrientation().indexOf('portrait') >= 0;

  const onPlay = useCallback(() => {
    setShowNextVideo(false);
    setShowReplay(false);

    // Track the initial playback
    if (!isIdle) {
      trackEvent({
        category: 'Videos',
        action: 'Play',
        label: videoTitle,
        entity_id: id,
        position: videoIndex,
      });
    }
    if (isSmallScreen && getDeviceOrientation().indexOf('portrait') >= 0) {
      plyrInstance.current?.pause();
    }
  }, [id, isIdle, isSmallScreen, trackEvent, videoIndex, videoTitle]);

  const onEnd = useCallback(() => {
    if (videoData?.nextVideo) {
      setShowNextVideo(true);
      setShowReplay(true);
    } else {
      history.push(homePath);
    }
  }, [history, homePath, videoData]);

  const onProgress = useCallback(
    (progress: number) => {
      // Track progress updates
      if (!isIdle) {
        trackEvent({
          category: 'Videos',
          action: `Progress Reached ${progress}%`,
          label: videoTitle,
          entity_id: id,
          position: videoIndex,
        });
      }
    },
    [id, isIdle, trackEvent, videoIndex, videoTitle]
  );

  const onPauseVideo = useCallback(() => {
    if (!isSmallScreenPortrait) {
      setShowTitleBar(true);
    }
  }, [isSmallScreenPortrait]);

  const pauseVideo = useCallback(
    (pauseVideo: boolean) => {
      console.log('help', pauseVideo);
      if (!showNextVideo) {
        if (pauseVideo) {
          plyrInstance.current?.pause();
        } else {
          plyrInstance.current?.play();
        }
      }

      setInMobilePortrait(getDeviceOrientation().indexOf('portrait') >= 0);
      setHelpOpen(pauseVideo);
    },
    [showNextVideo]
  );

  const onVideoReplay = useCallback(() => {
    plyrInstance.current?.play();
  }, []);

  const onControlVisibilityChange = useCallback((isShown: boolean) => {
    // Show / hide title bar
    setShowTitleBar(isShown);
  }, []);

  // Increment the autoplay count after autoplay timeout
  const onAutoplay = useCallback(() => {
    setPlayCount((count) => count + 1);

    if (!isIdle) {
      trackEvent({
        category: 'Videos',
        action: 'Next Video Auto Played',
        label: videoTitle,
        entity_id: id,
        position: videoIndex + 1,
      });
    }
  }, [id, isIdle, setPlayCount, trackEvent, videoIndex, videoTitle]);

  // Continue watching videos when confirmed from 'Are you still watching'
  const continuePlaying = () => {
    setPlayCount(0);
    setAutoplay(true);
    setShowIdleMessage(false);
    plyrInstance.current?.play();
    trackEvent({
      category: 'Videos',
      action: 'Idle Message Confirmed',
      label: videoTitle,
      entity_id: id,
      position: videoIndex,
    });
  };

  // Redirect home
  const redirectHome = () => {
    history.push(homePath);
  };

  useEffect(() => {
    if (playCount >= 2 && isIdle) {
      plyrInstance.current?.pause();
      setAutoplay(false);
      setShowIdleMessage(true);
      trackEvent({
        category: 'Videos',
        action: 'Idle Message Displayed',
        label: videoTitle,
      });
    }
  }, [isIdle, playCount, trackEvent, videoTitle]);

  useEffect(() => {
    const handleRotation = () => {
      const newOrientation = getDeviceOrientation();
      if (!helpOpen) {
        setInMobilePortrait(newOrientation.indexOf('portrait') >= 0);
      }

      if (!browser.isIos) {
        if (newOrientation.indexOf('landscape') >= 0) {
          plyrInstance.current?.play();
        } else {
          plyrInstance.current?.pause();
        }
      }
    };

    window.addEventListener('orientationchange', handleRotation);

    return () => {
      window.removeEventListener('orientationchange', handleRotation);
    };
  }, [helpOpen]);

  return (
    <>
      <SEO title={videoTitle} />
      <Main>
        <Loader isVisible={isLoading} />
        {isError && <ErrorMessage message={error?.message} />}
        {id && !isLoading && !isError && videoData?.video && (
          <ThemeToggle mode='dark'>
            <AnimatePresence>
              {showTitleBar && !inMobilePortrait && !showIdleMessage && (
                <TitleBar
                  smallScreen={isSmallScreen}
                  exhibitName={data?.title || ''}
                  toggleHelp={pauseVideo}
                  onReturn={() => history.push(homePath)}
                  content={data?.fieldHelp?.processed}
                >
                  <VideoTitle>
                    {!isSmallScreen ? videoData.video.videoTitle : ''}
                  </VideoTitle>
                </TitleBar>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {showReplay && showNextVideo && !showIdleMessage && (
                <HeroLayout mode='single' overlay={true}>
                  <BlurOverlay
                    zIndex={1}
                    variant='left'
                    height={
                      showTitleBar
                        ? `calc(100vh - ${isSmallScreen ? '60px' : '90px'})`
                        : '100%'
                    }
                  />
                  <ContentBlock
                    mainTitle={videoData.video.videoTitle || ''}
                    layout={'left'}
                  >
                    <Button
                      onClick={onVideoReplay}
                      variant='outline'
                      mt={isSmallScreen ? 1 : 4}
                    >
                      <Icon type='plyr-restart' /> Replay video
                    </Button>
                  </ContentBlock>
                </HeroLayout>
              )}
            </AnimatePresence>
            <Video
              src={videoData?.video.videoUrl || ''}
              isSmallScreen={isSmallScreen}
              captions={videoData?.video.tags?.captions}
              onPlay={onPlay}
              onEnd={onEnd}
              onProgress={onProgress}
              onPause={onPauseVideo}
              onControlVisibilityChange={onControlVisibilityChange}
              hideOverlayToggle={showReplay}
              plyrInstance={plyrInstance}
              inMobilePortrait={inMobilePortrait}
            />
            <AnimatePresence>
              {showNextVideo && !showIdleMessage && videoData?.nextVideo && (
                <>
                  <NextVideo
                    videoId={`${videoData?.nextVideo?.videoId}`}
                    videoTitle={videoData?.nextVideo?.videoTitle || ''}
                    thumbnail={videoData.nextVideo.videoThumbnail || ''}
                    autoplay={autoplay}
                    isHelpOpen={helpOpen}
                    onAutoplay={onAutoplay}
                    videoTags={videoData.nextVideo.tags}
                    isSmallScreen={isSmallScreen}
                  ></NextVideo>
                </>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {showIdleMessage && (
                <ActionMessage
                  onConfirm={continuePlaying}
                  onCancel={redirectHome}
                  title='Are you still there?'
                  secondaryTitle={videoData.video.videoTitle || ''}
                  smallScreen={isSmallScreen}
                />
              )}
            </AnimatePresence>
            <AnimatePresence>
              {isSmallScreenPortrait && !showIdleMessage && (
                <RotateScreenMessage />
              )}
            </AnimatePresence>
          </ThemeToggle>
        )}
      </Main>
    </>
  );
};

export default Watch;
