import { useMediaQuery } from '@react-hook/media-query';
import Button from 'components/button';
import ContentBlock from 'components/content-block';
import ErrorMessage from 'components/error-message';
import HeroBackground from 'components/hero-background';
import HeroLayout from 'components/hero-layout';
import Icon from 'components/icon';
import Loader from 'components/loader';
import Main from 'components/main';
import MetaTags from 'components/meta-tags';
import SEO from 'components/seo';
import ThemeToggle from 'components/theme-toggle';
import TitleBar from 'components/title-bar';
import VideoCard from 'components/video-card';
import VideoGrid from 'components/video-grid';
import { AnimatePresence } from 'framer-motion';
import { useExperience } from 'hooks/use-experience';
import { useThemeColor } from 'hooks/use-theme-color';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Link } from 'react-router-dom';
import { useTracking } from 'react-tracking';
import { breakpoints } from 'settings/styles';
import { Video } from 'types/interfaces';
import { getDeviceOrientation, getExhibitData, getMode } from 'utils/helpers';

export type LandingProps = {
  setPlayCount: Dispatch<SetStateAction<number>>;
};

const Landing: FC<LandingProps> = ({ setPlayCount }) => {
  const [gridHeight, setGridHeight] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);
  const [inMobilePortrait, setInMobilePortrait] = useState(
    getDeviceOrientation().indexOf('portrait') >= 0
  );

  // Main Experience Data
  const { data, error, isLoading, isError } = useExperience();
  const mode = getMode(data);

  const constraintsRef = useRef<HTMLDivElement>(null);

  // Clear the play count
  useEffect(() => {
    setPlayCount(0);
  }, [setPlayCount, constraintsRef, mode]);

  // Set the grid height
  useEffect(() => {
    if (mode === 'multiple' && constraintsRef) {
      setGridHeight(constraintsRef.current?.clientHeight || 0);
    }
  }, [mode]);

  // Tracking
  const { trackEvent } = useTracking();

  const handleCardClick = useCallback(
    (index: number, video: Video) => {
      setSelectedVideo(video);

      trackEvent({
        category: 'Landing Page',
        action: `Video Clicked`,
        entity_id: video.videoId,
        label: video.videoTitle,
        position: index,
      });
    },
    [trackEvent]
  );

  const exhibit = useMemo(() => {
    if (data) {
      return getExhibitData(data);
    }
    return null;
  }, [data]);

  // The active theme color
  // TODO: update mode and layout based on data from the API once available
  const themeColor = useThemeColor(exhibit?.bgImageUrl);

  const isSmallScreen = useMediaQuery(
    `screen and (max-width: ${breakpoints[2]})`
  );

  // Whether to show the grid on smaller screens
  // Only displayed when below the medium breakpoint and no video is selected
  const showGrid = useMemo(() => {
    if (isSmallScreen) {
      if (selectedVideo === null) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  }, [isSmallScreen, selectedVideo]);

  // Detect mobile responsive update
  useEffect(() => {
    const handleRotation = () => {
      const newOrientation = getDeviceOrientation();
      setInMobilePortrait(newOrientation.indexOf('portrait') >= 0);
    };

    window.addEventListener('orientationchange', handleRotation);

    return () => {
      window.removeEventListener('orientationchange', handleRotation);
    };
  }, []);

  return (
    <>
      <SEO title={data?.title || ''} />
      <Loader isVisible={isLoading} />
      {isError && <ErrorMessage message={error?.message} />}
      {!isLoading && !isError && (
        <ThemeToggle mode={themeColor}>
          <Main>
            <TitleBar
              smallScreen={isSmallScreen}
              exhibitName={exhibit?.title || ''}
              isHome={selectedVideo ? false : true}
              onReturn={() => setSelectedVideo(null)}
              content={data?.fieldHelp?.processed}
            />
            <AnimatePresence
              exitBeforeEnter
              initial={false}
              onExitComplete={() =>
                window.scrollTo({ top: 0, behavior: 'smooth' })
              }
            >
              {!selectedVideo ? (
                <HeroLayout mode={mode} gridHeight={gridHeight} key='hero'>
                  <HeroBackground
                    backgroundImage={exhibit?.bgImageUrl || ''}
                    backgroundImageAlt={exhibit?.bgImageAlt || ''}
                  />
                  <ContentBlock
                    mainTitle={
                      (mode === 'single'
                        ? exhibit?.exhibitVideos[0]?.videoTitle
                        : exhibit?.title) || ''
                    }
                    subTitle={
                      mode === 'single'
                        ? exhibit?.exhibitVideos[0]?.videoSubTitle
                        : exhibit?.subTitle
                    }
                    bodyText={
                      mode === 'single'
                        ? exhibit?.exhibitVideos[0]?.videoDescription
                        : exhibit?.description || ''
                    }
                    layout={'left'}
                  >
                    {mode === 'single' && (
                      <Button
                        as={Link}
                        to={`watch/${exhibit?.exhibitVideos[0]?.videoId}`}
                        mt={[3]}
                      >
                        <Icon type='plyr-play' /> Play video
                      </Button>
                    )}
                  </ContentBlock>
                </HeroLayout>
              ) : (
                <HeroLayout
                  mode={mode}
                  gridHeight={gridHeight}
                  key={selectedVideo.videoId}
                >
                  <HeroBackground
                    backgroundImage={selectedVideo.bgImageUrl || ''}
                    backgroundImageAlt={selectedVideo.bgImageAlt || ''}
                  />
                  <ContentBlock
                    mainTitle={selectedVideo.videoTitle}
                    subTitle={selectedVideo.videoSubTitle}
                    bodyText={selectedVideo.videoDescription}
                    layout={'left'}
                  >
                    <MetaTags tags={selectedVideo.tags} />
                    <Button
                      as={Link}
                      to={`watch/${selectedVideo.videoId}`}
                      mt={[4, null, 3, 5]}
                    >
                      <Icon type='plyr-play' /> Play video
                    </Button>
                  </ContentBlock>
                </HeroLayout>
              )}
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter initial={false}>
              {mode === 'multiple' && showGrid && (
                <div ref={constraintsRef}>
                  <VideoGrid key='grid' isMobilePortrait={inMobilePortrait}>
                    {exhibit?.exhibitVideos.map(
                      (item, index) =>
                        item && (
                          <VideoCard
                            isActive={
                              selectedVideo &&
                              selectedVideo.videoId === item.videoId
                            }
                            key={item.videoTitle || ''}
                            title={item.videoTitle || ''}
                            image={item.videoThumbnail || ''}
                            onClick={() => handleCardClick(index, item)}
                          />
                        )
                    )}
                  </VideoGrid>
                </div>
              )}
            </AnimatePresence>
          </Main>
        </ThemeToggle>
      )}
    </>
  );
};

export default Landing;
