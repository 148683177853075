import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled from 'styled-components';

export const ModalWindowStyled = styled(motion.div)`
  position: fixed;
  left: 10%;
  display: flex;
  justify-content: center;
  width: 80%;
  height: 75vh;
  padding: ${({ theme }) => theme.spaces.large};
  color: ${({ theme }) => theme.colors.black};
  background: ${({ theme }) => theme.colors.background};
  border-radius: ${({ theme }) => theme.spaces.small};

  ${({ theme }) => theme.mediaQueries.large} {
    min-height: ${({ theme }) => theme.size.small};
    max-height: calc(80vh - 120px);
  }
`;

export const ModalOverlay = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 0.5 },
}))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100vh;
  background: ${({ theme }) => rgba(theme.colors.background, 0.3)};
`;

export const ModelWindowContent = styled.div`
  width: 100%;
  padding-right: ${({ theme }) => theme.spaces.small};
  overflow: auto;
  color: ${({ theme }) => theme.colors.text};

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => rgba(theme.colors.background, 0.1)};
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px
      ${({ theme }) => rgba(theme.colors.background, 0.1)};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.yellow};
    border-radius: 10px;
  }
`;

export const ModalCloseButton = styled.button`
  position: absolute;
  top: -16px;
  right: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background: ${({ theme }) => theme.colors.yellow};
  border: none;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 2px 4px ${({ theme }) => rgba(theme.colors.black, 0.2)};

  svg {
    display: inline-block;
    width: 12px;
    height: 12px;
    stroke: ${({ theme }) => theme.colors.black};
  }
`;
