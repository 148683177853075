import React, { FC, useMemo } from 'react';
import { SpaceProps } from 'styled-system';
import { H1, H2, H3, H4 } from './heading.styled';

export type HeadingProps = {
  type: 'h1' | 'h2' | 'h3' | 'h4';
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
> &
  SpaceProps;

const Heading: FC<HeadingProps> = ({ type, ...props }) => {
  const HeadingComponent: FC = useMemo(() => {
    switch (type) {
      case 'h1':
        return H1;
      case 'h2':
        return H2;
      case 'h3':
        return H3;
      case 'h4':
        return H4;
      default:
        return H1;
    }
  }, [type]);

  return <HeadingComponent {...props} />;
};

export default Heading;
