import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  right: ${({ theme }) => theme.spaces.large};
  bottom: ${({ theme }) => theme.spaces.large};
  z-index: 10;
  opacity: 0;
  transition: opacity ${({ theme }) => theme.transitions.ease};

  &:hover {
    opacity: 1;
  }
`;

export const Select = styled.select`
  padding: ${({ theme }) => theme.spaces.small};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.background};
  border: 1px solid ${({ theme }) => theme.colors.yellow};
  border-radius: 4px;
  appearance: none;
`;
