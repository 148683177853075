import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface SEO {
  title?: string;
}

const SEO: FC<SEO> = ({ title }) => {
  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${process.env.REACT_APP_NAME}`}
    />
  );
};

export default SEO;
