import Heading from 'components/heading';
import React, { FC } from 'react';
import { VideoTitleStyled } from './video-title.styled';

export type VideoTitle = {
  showReplay?: boolean;
  onReplay?: () => void;
};

const VideoTitle: FC<VideoTitle> = ({ children }) => {
  return (
    <VideoTitleStyled>
      <Heading type='h4'>{children}</Heading>
    </VideoTitleStyled>
  );
};

export default VideoTitle;
