import { motion } from 'framer-motion';
import styled from 'styled-components';

export const AnimatedIcon = styled(motion.div).attrs(() => ({
  initial: { rotate: 0 },
  animate: { rotate: 90 },
  transition: { ease: 'easeInOut', delay: 1, duration: 1 },
}))`
  color: ${({ theme }) => theme.colors.yellow};

  svg {
    width: 120px;
    height: 120px;
  }
`;
