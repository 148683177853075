import { setCSSUnit } from 'utils/helpers';

export const sizes = [0, 200, 400, 600, 800, 1000];

export const size = {
  xSmall: setCSSUnit(sizes[1]),
  small: setCSSUnit(sizes[2]),
  medium: setCSSUnit(sizes[3]),
  large: setCSSUnit(sizes[4]),
  xLarge: setCSSUnit(sizes[5]),
};
