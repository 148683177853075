import React, { FC } from 'react';
import { AnimateCircle, NumberCircleStyled } from './number-circle.styled';

export enum IconSizes {
  default = 'default',
  large = 'large',
}

export type NumberCircle = {
  duration: number;
  count?: number;
  showCounter?: boolean;
  size?: keyof typeof IconSizes;
};

const NumberCircle: FC<NumberCircle> = ({
  duration,
  count = 0,
  showCounter = true,
  size = 'default',
}) => {
  return (
    <NumberCircleStyled size={size} viewBox='0 0 40 40'>
      <AnimateCircle
        dur={duration}
        cx='20'
        cy='20'
        r='15.91549430918954'
        fill='transparent'
      />
      {showCounter && count && (
        <text
          y='50%'
          x='50%'
          transform='translate(0, 5)'
          stroke='none'
          fill='white'
        >
          <tspan x='50%' y='50%' textAnchor='middle'>
            {count}
          </tspan>
        </text>
      )}
    </NumberCircleStyled>
  );
};

export default NumberCircle;
