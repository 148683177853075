import { useEffect, useRef, useState } from 'react';
import { __DEV__ } from 'utils/helpers';
const ColorThief = require('color-thief');
const { rgb } = require('wcag-contrast');

/** Return a theme color based on the background image */
export function useThemeColor(imageUrl?: string | null) {
  const colorThief = useRef<any>();
  const [themeColor, setThemeColor] = useState<'dark' | 'light'>('dark');

  useEffect(() => {
    colorThief.current = new ColorThief();
  });

  useEffect(() => {
    // Used for local dev to avoid CORS issues
    const googleProxyURL =
      'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
    const img = new Image();

    // Assume the default is white text on dark background
    const setColorMode = () => {
      const contrast = rgb([255, 255, 255], colorThief.current?.getColor(img));

      if (contrast >= 4.5) {
        setThemeColor('dark');
      } else {
        setThemeColor('light');
      }
    };

    img.addEventListener('load', setColorMode);

    if (imageUrl) {
      img.crossOrigin = 'Anonymous';
      img.src = __DEV__
        ? googleProxyURL + encodeURIComponent(imageUrl)
        : imageUrl;
      img.width = 1920;
      img.height = 1080;
    }

    return () => {
      img.removeEventListener('load', setColorMode);
    };
  }, [imageUrl]);

  return themeColor;
}
