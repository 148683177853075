import { colors } from './colors';
import { fontSize, fontSizes } from './font-size';
import { fonts } from './fonts';
import { breakpoints, mediaQueries } from './responsive';
import { size, sizes } from './size';
import { space, spaces } from './space';
import { transitions } from './transitions';
import { zIndex } from './z-index';

export const theme = {
  breakpoints,
  colors,
  mediaQueries,
  space,
  spaces,
  fontSize,
  fonts,
  fontSizes,
  size,
  sizes,
  zIndex,
  transitions,
};
