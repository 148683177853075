import { Notifier } from '@airbrake/browser';
import React from 'react';
import { SETTINGS } from 'settings/config';

interface Props {}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  };

  airbrake = SETTINGS.airbrake.enabled
    ? new Notifier({
        projectId: SETTINGS.airbrake.projectId,
        projectKey: SETTINGS.airbrake.projectKey,
      })
    : null;

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Display fallback UI
    this.setState({ hasError: true });

    // Send error to Airbrake
    this.airbrake?.notify({
      error: error,
      params: { info: errorInfo },
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return children;
  }
}

export default ErrorBoundary;
