import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-weight: normal;
    font-size: 16px;
    word-wrap: break-word;
    background-color: ${({ theme }) => theme.colors.background};

    ${({ theme }) => theme.mediaQueries.large} {
      font-size: 18px;
    }
  }

  body {
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.fonts.body};
  }

  a {
    color: ${({ theme }) => theme.colors.text};
  }
`;
