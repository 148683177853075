import { rgba } from 'polished';
import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  text-decoration: none;
  background: none;
  border: none;
  outline: none;
`;

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: calc((200 / 350) * 100%);
  overflow: hidden;
  background-color: ${({ theme }) => rgba(theme.colors.text, 0.15)};

  &.active::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 4px solid ${({ theme }) => theme.colors.yellow};
    content: '';
  }
`;

export const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
`;
