import BlurOverlay from 'components/blur-overlay';
import { AnimatePresence } from 'framer-motion';
import React, { FC, useEffect, useRef, useState } from 'react';
import { ImageWrapper, VideoWrapper } from './hero-background.styled';

export type HeroBackgroundProps = {
  blurPosition?: 'full' | 'left' | 'leftAndBottom';
  backgroundImage: string;
  backgroundImageAlt?: string;
  backgroundVideoUrl?: string;
};

const HeroBackground: FC<HeroBackgroundProps> = ({
  backgroundImage,
  backgroundImageAlt,
  backgroundVideoUrl,
  blurPosition = 'leftAndBottom',
}) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [imageVisibility, setImageVisibility] = useState(false);

  const videoEl = useRef<HTMLVideoElement>(null);
  const imageEl = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const el = videoEl.current;
    const handleLoaded = () => {
      setIsVideoLoaded(true);
    };

    if (backgroundVideoUrl) {
      el?.addEventListener('loadeddata', handleLoaded);
    } else {
      setIsVideoLoaded(false);
    }

    return () => {
      el?.removeEventListener('loadeddata', handleLoaded);
    };
  }, [backgroundVideoUrl]);

  useEffect(() => {
    const imgEl = imageEl.current;
    const handleLoaded = () => {
      setImageVisibility(true);
    };

    imgEl?.addEventListener('load', handleLoaded);

    return () => {
      imgEl?.removeEventListener('load', handleLoaded);
    };
  });

  return (
    <>
      <BlurOverlay
        zIndex={1}
        height={['var(--hero-background-offset)', null, '100vh']}
        variant={['bottom', null, blurPosition]}
      />
      <AnimatePresence>
        {backgroundVideoUrl && (
          <VideoWrapper>
            <video ref={videoEl} autoPlay muted loop src={backgroundVideoUrl} />
          </VideoWrapper>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isVideoLoaded && (
          <ImageWrapper
            ref={imageEl}
            src={backgroundImage}
            alt={backgroundImageAlt}
            animate={{ opacity: imageVisibility ? 1 : 0 }}
          />
        )}
      </AnimatePresence>
    </>
  );
};

export default HeroBackground;
