import createActivityDetector from 'activity-detector';
import { useEffect, useState } from 'react';
import { SETTINGS } from 'settings/config';

/** Return a boolean based on whether the user is currently interacting with the page */
export function useIdle() {
  const [isIdle, setIsIdle] = useState(false);

  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: SETTINGS.idleTimeout,
    });

    activityDetector.on('idle', () => {
      setIsIdle(true);
    });

    activityDetector.on('active', () => {
      setIsIdle(false);
    });

    return () => {
      activityDetector.stop();
    };
  }, []);

  return isIdle;
}
