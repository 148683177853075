export const fonts = {
  brandonText: `'Brandon Text', sans-serif`,

  get heading() {
    return this.brandonText;
  },

  get body() {
    return this.brandonText;
  },
};
