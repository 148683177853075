import Heading from 'components/heading';
import Icon from 'components/icon';
import ModalWindow from 'components/modal';
import Text from 'components/text';
import { AnimatePresence } from 'framer-motion';
import React, { FC, useCallback, useState } from 'react';
import { useTracking } from 'react-tracking';
import {
  HelpButton,
  HelpWrapper,
  HomeLink,
  TitleBarContent,
  TitleBarStyled,
} from './title-bar.styled';

export type TitleBar = {
  exhibitName: string;
  isHome?: boolean;
  smallScreen: boolean;
  toggleHelp?: (isVisible: boolean) => void;
  onReturn?: () => void;
  content?: string | null;
};

const TitleBar: FC<TitleBar> = ({
  children,
  exhibitName,
  isHome = false,
  smallScreen,
  toggleHelp,
  onReturn,
  content,
}) => {
  const [showHelpModal, setShowModalHelp] = useState(false);
  const { trackEvent } = useTracking();

  const toggleHelpModal = useCallback(() => {
    setShowModalHelp((val) => {
      // Track open/close event
      trackEvent({
        category: 'Help',
        action: `Dialog ${val ? 'Closed' : 'Opened'}`,
        label: 'Help Dialog',
      });
      return !val;
    });
    toggleHelp && toggleHelp(!showHelpModal);
  }, [showHelpModal, toggleHelp, trackEvent]);

  return (
    <>
      <TitleBarStyled>
        {!isHome ? (
          <HomeLink title='Return home' onClick={onReturn && onReturn}>
            <Icon type='back-icon' />
            <Text size='small'>{exhibitName}</Text>
          </HomeLink>
        ) : (
          // Need empty div or container for flex-box layout
          <div></div>
        )}
        <TitleBarContent>{children}</TitleBarContent>
        {content && (
          <HelpWrapper>
            <HelpButton onClick={toggleHelpModal}>
              <Text size='small'>Help</Text>
              <Icon type='help-icon' />
            </HelpButton>
          </HelpWrapper>
        )}
      </TitleBarStyled>
      <AnimatePresence>
        {showHelpModal && (
          <ModalWindow onClose={toggleHelpModal} smallScreen={smallScreen}>
            <Heading type='h3'>Help</Heading>
            {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
          </ModalWindow>
        )}
      </AnimatePresence>
    </>
  );
};

export default TitleBar;
