import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled from 'styled-components';

export const StyledNextVideo = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, x: 50, y: '-50%' },
  animate: { opacity: 1, x: 0, y: '-50%' },
  exit: { opacity: 0, x: 50, y: '-50%' },
  transition: { ease: 'easeInOut', delay: 0.3 },
}))`
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 40%;
  max-width: 700px;
  height: auto;
  padding: ${({ theme }) => theme.spaces.large};
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => rgba(theme.colors.background, 0.7)};
  backdrop-filter: blur(20px);

  ${({ theme }) => theme.mediaQueries.large} {
    padding: ${({ theme }) => theme.spaces.xLarge};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
`;

export const Thumbnail = styled.img`
  width: 100%;
`;

export const ThumbnailWrapper = styled.div`
  display: grid;
  grid-template-columns: 70% 1fr;
  margin: 0 -${({ theme }) => theme.spaces.large} 0 0;

  ${({ theme }) => theme.mediaQueries.large} {
    display: block;
    margin: 0;
  }
`;

export const MobileAutoPlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Details = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.spaces.small};
  font-size: ${({ theme }) => theme.fontSize.small};

  span {
    margin-left: ${({ theme }) => theme.spaces.medium};
  }
`;

export const AutoplayWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${({ theme }) => theme.spaces.medium};

  ${({ theme }) => theme.mediaQueries.large} {
    margin-top: ${({ theme }) => theme.spaces.large};

    a {
      margin-right: ${({ theme }) => theme.spaces.small};
    }
  }
`;
