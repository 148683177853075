import Text from 'components/text';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

type TextBlockProps = {
  trunc: boolean;
};

type BlockWrapper = {
  layout: 'left' | 'right' | undefined;
};

export const LayoutWrapper = styled(motion.div).attrs((props) => ({
  initial: { opacity: 0, x: -50 },
  animate: { opacity: 1, x: 0 },
  exit: { opacity: 0, x: -50 },
  transition: { ease: 'easeInOut', duration: 0.5 },
}))`
  z-index: 1;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.medium} {
    display: grid;
    grid-template-areas: 'left right';
    grid-template-columns: 1fr 1fr;
  }
`;

export const BlockWrapper = styled.div<BlockWrapper>`
  ${(props) => {
    const position = props.layout === 'right' ? 'right' : 'left';

    return css`
      grid-area: ${position};
      margin: ${({ theme }) => theme.spaces.large} 0;
    `;
  }}
`;

export const BlockTextWrapper = styled(Text)`
  ${({ theme }) => theme.mediaQueries.medium} {
    max-height: calc(50vh - 250px);
    overflow: auto;
  }
`;

export const BlockText = styled.div<TextBlockProps>`
  ${(props) => {
    const clamp = props.trunc ? 3 : 'unset';

    return css`
      -webkit-line-clamp: ${clamp};
    `;
  }}

  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  transition: all ${({ theme }) => theme.transitions.ease};

  div > * {
    font-size: 1rem;

    ${({ theme }) => theme.mediaQueries.medium} {
      font-size: ${({ theme }) => theme.fontSize.medium};
    }
  }
`;

export const ReadMoreToggle = styled.button<TextBlockProps>`
  display: block;
  margin-top: ${({ theme }) => theme.fontSize.small};
  padding: 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ theme }) => theme.fontSize.xSmall};
  line-height: ${({ theme }) => theme.spaces.large};
  text-align: left;
  background: none;
  border: none;
  outline: none;

  &::after {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-top: -1px;
    margin-left: ${({ theme }) => theme.spaces.medium};
    border-color: currentColor;
    border-style: solid;
    border-width: 2px 2px 0 0;
    transition: transform ${({ theme }) => theme.transitions.ease};
    content: '';

    ${(props) => {
      const rotate = props.trunc ? '135deg' : '-45deg';

      return css`
        transform: rotate(${rotate});
      `;
    }}
  }
`;
