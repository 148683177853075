import { fontSize } from 'settings/styles';
import styled from 'styled-components';
import { space } from 'styled-system';

export const StyledText = styled.p<{ size: keyof typeof fontSize }>`
  margin: 0;
  color: ${({ theme }) => theme.colors.text};
  font-size: ${({ size, theme }) => theme.fontSize[size]};
  line-height: 1.2;
  transition: color ${({ theme }) => theme.transitions.ease};

  /* Styled system overrides */
  ${space}
`;
