import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as LogoImage } from './logo.svg';

export const Wrapper = styled(motion.div).attrs(() => ({
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}))`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.black};
`;

export const Logo = styled(LogoImage)`
  position: absolute;
  width: 100%;
  height: 100%;
`;

export const LogoWrapper = styled(motion.div).attrs(() => ({
  animate: { scale: [1, 1.1, 1] },
  exit: { scale: 2, transition: { loop: false } },
  transition: { loop: Infinity },
}))`
  --size: 100px;

  position: relative;
  width: var(--size);
  height: var(--size);

  ${({ theme }) => theme.mediaQueries.large} {
    --size: 200px;
  }
`;
