import MetaTags from 'components/meta-tags';
import Text from 'components/text';
import React, { FC } from 'react';
import { VideoMetaTags } from 'types/interfaces';
import { Card, Image, ImageWrapper } from './video-card.styled';

export type VideoCardProps = {
  title: string;
  image: string;
  isActive?: boolean | null;
  meta?: VideoMetaTags;
  onClick?: () => void;
};

const VideoCard: FC<VideoCardProps> = ({
  image,
  title,
  meta,
  isActive = false,
  onClick,
}) => {
  return (
    <Card onClick={onClick}>
      <ImageWrapper className={isActive ? 'active' : ''}>
        {image && <Image src={image} alt={title} />}
      </ImageWrapper>
      <Text size='small' mt={3} mb={meta ? 2 : 0}>
        {title}
      </Text>
      {meta && <MetaTags tags={meta} />}
    </Card>
  );
};

export default VideoCard;
