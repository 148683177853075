import React from 'react';
import { LayoutWrapper } from './hero-layout.styled';

export type HeroLayoutProps = {
  mode: 'single' | 'multiple' | undefined;
  gridHeight?: number;
  overlay?: boolean;
};

const HeroLayout: React.FC<HeroLayoutProps> = ({
  children,
  mode,
  overlay = false,
  gridHeight = 0,
}) => {
  return (
    <LayoutWrapper mode={mode} overlay={overlay} gridHeight={gridHeight}>
      {children}
    </LayoutWrapper>
  );
};

export default HeroLayout;
