import { getParams } from '@hatchd/utils';
import { getExperience } from 'api/experience';
import { useQuery } from 'react-query';

/** Return Experience Data from the API */
export function useExperience(id?: string) {
  const { location } = window;
  const { experienceID } = getParams<{ experienceID: string }>(location.search);

  return useQuery(['experience', experienceID], getExperience, {
    retry: false,
  });
}
