import { MultimediaData } from 'api/experience';
import { JourneyFragmentFragment } from 'api/graphql';
import { Exhibit, Video, VideoMetaTags } from '../types/interfaces';

export const __DEV__ = process.env.REACT_APP_ENV === 'development';

export async function fetchData<T extends Object>(
  input: RequestInfo,
  init?: RequestInit
): Promise<T> {
  try {
    const res = await fetch(input, init);
    return await res.json();
  } catch (error) {
    throw Error(error);
  }
}

type ExhibitPerspectives = JourneyFragmentFragment['perspectives'];

// Retrieve the position of a video within the list of videos of the exhibit
const getVideoIndex = (id: number, videos: ExhibitPerspectives) =>
  videos?.findIndex(
    (video) =>
      video?.perspective?.__typename === 'NodePerspective' &&
      video.perspective.nid === id
  ) || 0;

// Retrieve the next video in the list from a specified video (if one exists)
const getNextVideoByID = (id: number, videos: ExhibitPerspectives) => {
  const totalVideos = videos?.length;
  const videoIndex = getVideoIndex(id, videos);
  if (totalVideos && totalVideos > 1) {
    if (videoIndex < totalVideos) {
      return videos?.[videoIndex + 1]?.perspective;
    } else {
      return videos?.[0]?.perspective;
    }
  }
  return null;
};

// Retrieve the current video data
export const getVideoByID = (id: number, videos: ExhibitPerspectives) => {
  const videoIndex = getVideoIndex(id, videos);
  if (videoIndex === -1 || !videos) {
    return null;
  }
  return videos[videoIndex]?.perspective;
};

// Retrieve the full video data for the watch page, including current video and next video
export const getFullVideoData = (id: number, data: MultimediaData) => {
  const journey = getJourneyData(data);
  const currentVideo = getVideoByID(id, journey?.perspectives);
  const nextVideo = getNextVideoByID(id, journey?.perspectives);
  return {
    currentVideoIdx: getVideoIndex(id, journey?.perspectives),
    video: getFormattedVideo(currentVideo),
    nextVideo: getFormattedVideo(nextVideo),
  };
};

type VideoDataType = ReturnType<typeof getVideoByID>;

export const getExperienceData = (data: MultimediaData) => {
  if (data?.fieldExperience?.[0]?.entity?.__typename === 'NodeExperience') {
    return data.fieldExperience[0].entity;
  } else {
    return null;
  }
};

export const getJourneyData = (data: MultimediaData) => {
  const experience = getExperienceData(data);
  const journey = experience?.fieldJourneys?.[0];
  const journeyWithFragment = journey as typeof journey & {
    [key: number]: JourneyFragmentFragment;
  };

  const id = journey?.targetId;

  if (id && journeyWithFragment?.[id]) {
    return journeyWithFragment[id];
  } else {
    return null;
  }
};

// Retrieve the exhibit data for the hero component on the landing page
export const getExhibitData = (data: MultimediaData | null) => {
  const journey = getJourneyData(data);

  const exhibit: Exhibit = {
    title: journey?.title || '',
    subTitle: '',
    description: journey?.description?.processed || '',
    exhibitVideos: [],
  };

  if (journey?.fieldBillboard?.asset?.__typename === 'MediaImage') {
    exhibit.bgImageUrl =
      journey?.fieldBillboard?.asset?.media?.entity?.url || '';
    exhibit.bgImageAlt = journey?.fieldBillboard.asset.media?.alt || '';
  }

  // Get video array for this exhibit
  exhibit.exhibitVideos =
    journey?.perspectives?.map((item) => {
      if (item) {
        return getFormattedVideo(item.perspective);
      }
      return null;
    }) || [];

  return exhibit;
};

export const getMode = (data?: MultimediaData | null) => {
  if (data) {
    const journey = getJourneyData(data);
    return journey?.perspectives && journey.perspectives.length > 1
      ? 'multiple'
      : 'single';
  }
  return undefined;
};

export const setCSSUnit = (number: number, unit: string = 'px') =>
  `${number}${unit}`;

// Formatting the video data from JSON
const getFormattedVideo = (video: VideoDataType) => {
  if (video && video.__typename === 'NodePerspective') {
    const tags: VideoMetaTags = {
      ageRestriction: null,
      captions: {
        kind: 'captions',
        label: '',
        src: '',
        lang: 'en',
      },
      duration: null,
    };

    const newVideo: Video = {
      videoId: video.nid || 0,
      videoTitle: video.title || '',
      videoDescription: '',
      videoUrl: '',
      tags: tags,
      // TODO: Add subtitle and layout to video
      videoSubTitle: '',
      layout: 'left',
    };

    // Get video assets
    if (video?.fieldMedia?.[0]?.asset?.__typename === 'MediaVideo') {
      newVideo.videoThumbnail =
        video?.fieldMedia?.[0]?.asset?.fieldPosterImage?.entity?.url || '';
      newVideo.videoUrl = video?.fieldMedia[0].asset?.media?.entity?.url || '';
      if (video.fieldContent?.[0]?.entity?.__typename === 'ParagraphText') {
        newVideo.videoDescription =
          video.fieldContent?.[0]?.entity.fieldText?.processed || '';
      }
      newVideo.bgImageUrl =
        video?.fieldMedia[0].asset?.fieldPosterImage?.entity?.url || '';
      newVideo.bgImageAlt =
        video?.fieldMedia?.[0]?.asset?.fieldPosterImage?.alt || '';

      // Populate video meta tags
      newVideo.tags.captions.src =
        video.fieldMedia[0]?.asset?.fieldSubtilesFile?.entity?.url || '';
      newVideo.tags.duration =
        formatDuration(video.fieldMedia[0]?.asset?.fieldDuration) || null;
      newVideo.tags.ageRestriction =
        video.fieldMedia[0]?.asset?.fieldAgeClassification?.entity?.name ||
        null;
    }

    return newVideo;
  }

  return null;
};

const formatDuration = (dur: number | null | undefined) => {
  if (!dur) return null;

  const mins = Math.floor(dur / 60);
  const secs = dur - mins * 60;

  return `${mins}:${secs > 9 ? secs : '0' + secs} minutes`;
};

export const getDeviceOrientation = () => {
  let orientation = '';

  if ('orientation' in window.screen) {
    // screen.orientation is supported
    return window.screen.orientation.type;
  }

  switch (window.orientation) {
    case 0:
      orientation = '_portrait';
      break;
    case 180:
      orientation = '_portrait';
      break;
    case -90:
      orientation = '_landscape';
      break;
    case 90:
      orientation = '_landscape';
      break;
  }

  return orientation;
};

export const browser = {
  isEdge: window.navigator.userAgent.includes('Edge'),
  isWebkit:
    'WebkitAppearance' in document.documentElement.style &&
    !/Edge/.test(navigator.userAgent),
  isIPhone: /(iPhone|iPod)/gi.test(navigator.platform),
  isIos: /(iPad|iPhone|iPod)/gi.test(navigator.platform),
};
