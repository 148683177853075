import ErrorMessage from 'components/error-message';
import Main from 'components/main';
import SEO from 'components/seo';
import React, { FC } from 'react';

const NotFound: FC = () => {
  return (
    <>
      <SEO title='Page not found' />
      <Main>
        <ErrorMessage navigateHome={true} />
      </Main>
    </>
  );
};

export default NotFound;
