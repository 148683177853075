import Heading from 'components/heading';
import React, { FC, useEffect, useState } from 'react';
import {
  BlockText,
  BlockTextWrapper,
  BlockWrapper,
  LayoutWrapper,
  ReadMoreToggle,
} from './content-block.styled';

export type ContentBlockProps = {
  mainTitle: string;
  videoId?: number;
  layout?: 'left' | 'right' | undefined;
  subTitle?: string;
  bodyText?: string;
};

const ContentBlock: FC<ContentBlockProps> = ({
  children,
  mainTitle,
  videoId = 0,
  layout = 'left',
  subTitle,
  bodyText,
}) => {
  const [truncateBlock, setTruncateBlock] = useState(false);
  const [longText, setLongText] = useState(false);
  const [wrappedText, setWrappedText] = useState('');

  useEffect(() => {
    if (bodyText) {
      const regex = /(<([^>]+)>)/gi;
      const formattedBody = bodyText.replace(regex, '');
      const isLongText = formattedBody.split(' ').length >= 35;
      setTruncateBlock(isLongText);
      setLongText(isLongText);
    }
    setWrappedText(`<div>${bodyText}</div>`);
  }, [bodyText]);

  return (
    <LayoutWrapper>
      <BlockWrapper layout={layout}>
        <Heading type='h1' mb={3}>
          {mainTitle}
        </Heading>
        {subTitle && (
          <Heading type='h3' mb={3}>
            {subTitle}
          </Heading>
        )}
        {bodyText && (
          <BlockTextWrapper as='div'>
            <BlockText
              trunc={truncateBlock}
              dangerouslySetInnerHTML={{
                __html: wrappedText,
              }}
            ></BlockText>
          </BlockTextWrapper>
        )}
        {longText && (
          <ReadMoreToggle
            trunc={truncateBlock}
            onClick={() => {
              setTruncateBlock(!truncateBlock);
            }}
          >{`${truncateBlock ? 'Read more' : 'Show less'}`}</ReadMoreToggle>
        )}
        {children}
      </BlockWrapper>
    </LayoutWrapper>
  );
};

export default ContentBlock;
