import BlurOverlay from 'components/blur-overlay';
import Button from 'components/button';
import Heading from 'components/heading';
import NumberCircle from 'components/number-circle';
import { useInterval } from 'hooks/use-interval';
import React, { FC, useEffect, useState } from 'react';
import { SETTINGS } from 'settings/config';
import { Wrapper } from './action-message.styled';

export type ActionMessageProps = {
  title: string;
  secondaryTitle?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  smallScreen?: boolean;
};

const ActionMessage: FC<ActionMessageProps> = ({
  children,
  title,
  secondaryTitle,
  onConfirm,
  onCancel,
  smallScreen = false,
}) => {
  const [count, setCount] = useState(SETTINGS.autoplayTimeout);

  // Count down to cancel / go back home
  useInterval(() => {
    setCount((count) => {
      if (count > 0) {
        return count - 1;
      }
      return 0;
    });
  }, 1000);

  // callback when timer ends
  useEffect(() => {
    if (count === 0) {
      onCancel && onCancel();
    }
  }, [count, onCancel]);

  return (
    <>
      <BlurOverlay zIndex={1} variant={smallScreen ? 'solid' : 'full'} />
      <Wrapper>
        {secondaryTitle && (
          <Heading type='h3' mb={smallScreen ? 3 : 5}>
            {secondaryTitle}
          </Heading>
        )}
        <Heading type='h1' mb={smallScreen ? 4 : 5} mt={smallScreen ? 4 : 6}>
          {title}
        </Heading>
        <Button
          onClick={() => {
            onConfirm && onConfirm();
          }}
          mb={smallScreen ? 2 : 4}
        >
          Yes, continue watching
        </Button>
        <Button
          variant='outline'
          onClick={() => {
            onCancel && onCancel();
          }}
          mb={smallScreen ? 3 : 5}
        >
          Go back to home screen
        </Button>
        <NumberCircle
          count={count}
          duration={SETTINGS.autoplayTimeout}
          size={smallScreen ? 'default' : 'large'}
        ></NumberCircle>
        {children}
      </Wrapper>
    </>
  );
};

export default ActionMessage;
