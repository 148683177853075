import React, { FC, useMemo } from 'react';
import { colors } from 'settings/styles';
import { theme } from 'settings/styles/theme';
import { Theme, ThemeProvider } from 'styled-components';

export type ThemeToggle = {
  mode: 'light' | 'dark';
};

const ThemeToggle: FC<ThemeToggle> = ({ children, mode }) => {
  // Update theme properties based on the active mode
  const activeTheme = useMemo<Theme>(() => {
    if (mode === 'light') {
      return {
        ...theme,
        colors: {
          ...theme.colors,
          text: colors.black,
          background: colors.white,
        },
      };
    }
    return theme;
  }, [mode]);

  return <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>;
};

export default ThemeToggle;
