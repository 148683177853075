import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

const Common = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: var(--hero-background-offset);
  object-fit: cover;
  pointer-events: none;

  ${({ theme }) => theme.mediaQueries.medium} {
    height: 100vh;
  }
`;

export const ImageWrapper = styled(motion.img).attrs((props) => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}))`
  ${Common};
`;

export const VideoWrapper = styled(motion.div).attrs(() => ({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
}))`
  ${Common};

  video {
    width: 100%;
    height: 100%;
  }
`;
