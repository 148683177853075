import React, { ComponentType, FC } from 'react';
import { SpaceProps } from 'styled-system';
import { ButtonStyled } from './button.styled';

export enum ButtonTypes {
  default,
  outline,
}

type ButtonProps = {
  to?: string;
  as?: ComponentType<any>;
  variant?: keyof typeof ButtonTypes;
} & SpaceProps;

// all the props of a button element without its ref
export type Button = ButtonProps &
  React.PropsWithoutRef<JSX.IntrinsicElements['button']>;

const Button: FC<Button> = ({ children, as, to, ...props }) => {
  const { location } = window;

  if (as && to) {
    return (
      <ButtonStyled
        as={as}
        to={{ pathname: to, search: location.search }}
        {...props}
      >
        {children}
      </ButtonStyled>
    );
  }

  if (as) {
    return (
      <ButtonStyled as={as} {...props}>
        {children}
      </ButtonStyled>
    );
  }

  return <ButtonStyled {...props}>{children}</ButtonStyled>;
};

export default Button;
