import Icon from 'components/icon';
import ThemeToggle from 'components/theme-toggle';
import React, { FC } from 'react';
import {
  ModalCloseButton,
  ModalOverlay,
  ModalWindowStyled,
  ModelWindowContent,
} from './modal.styled';

export type ModalWindow = {
  onClose?: () => void;
  smallScreen: boolean;
};

const ModalWindow: FC<ModalWindow> = ({ children, onClose, smallScreen }) => {
  return (
    <ModalOverlay>
      <ThemeToggle mode='light'>
        <ModalWindowStyled
          initial={{ top: -20 }}
          animate={{ top: smallScreen ? 30 : 120 }}
          exit={{ top: -20 }}
          transition={{ duration: 0.5 }}
        >
          {onClose && (
            <ModalCloseButton
              onClick={() => {
                onClose && onClose();
              }}
            >
              <Icon type='close-icon' />
            </ModalCloseButton>
          )}
          <ModelWindowContent>{children}</ModelWindowContent>
        </ModalWindowStyled>
      </ThemeToggle>
    </ModalOverlay>
  );
};

export default ModalWindow;
