import { motion } from 'framer-motion';
import { rgba } from 'polished';
import styled from 'styled-components';

export const TitleBarStyled = styled(motion.div).attrs(() => ({
  initial: { opacity: 0, top: -50 },
  animate: { opacity: 1, top: 0 },
  exit: { opacity: 0, top: -50 },
}))`
  ${({ theme }) => `
    --bg-color: ${rgba(theme.colors.background, 0.8)};
    --bg-color-solid: ${theme.colors.background};
  `}

  --content-height: 20px;
  --outer-col-width: 100px;

  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: grid;
  grid-template-columns: minmax(var(--outer-col-width), 1fr) auto minmax(
      var(--outer-col-width),
      1fr
    );
  align-items: center;
  width: 100%;
  min-width: 200px;
  padding: ${({ theme }) => theme.spaces.medium};
  color: ${({ theme }) => theme.colors.text};

  ${({ theme }) => theme.mediaQueries.large} {
    padding: ${({ theme }) => theme.spaces.large};
    background: var(--bg-color);
    background: linear-gradient(
      to bottom,
      var(--bg-color-solid) 5%,
      transparent 80%
    );
  }

  ${({ theme }) => theme.mediaQueries.xLarge} {
    --outer-col-width: 300px;
    --content-height: 40px;
  }
`;

export const TitleBarContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--content-height);
`;

export const HomeLink = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  text-align: left;
  text-decoration: none;
  background: transparent;
  border: none;
  outline: none;

  svg {
    width: var(--content-height);
    height: var(--content-height);
    margin-right: ${({ theme }) => theme.spaces.medium};
    fill: currentColor;
  }
`;

export const HelpWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const HelpButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  color: ${({ theme }) => theme.colors.text};
  font-weight: bold;
  font-size: ${({ theme }) => theme.fontSize.small};
  background: transparent;
  border: none;
  outline: none;

  svg {
    width: var(--content-height);
    height: var(--content-height);
    margin-left: ${({ theme }) => theme.spaces.medium};
    fill: currentColor;
  }
`;
