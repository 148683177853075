import Button from 'components/button';
import Heading from 'components/heading';
import Text from 'components/text';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { __DEV__ } from 'utils/helpers';
import { Wrapper } from './error-message.styled';

export type ErrorMessageProps = {
  message?: string;
  navigateHome?: boolean;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ message, navigateHome }) => {
  const history = useHistory();

  const handleReload = () => {
    if (navigateHome) {
      history.push('/');
    }
    window.location.reload();
  };

  return (
    <Wrapper>
      <Heading type='h2'>Something went wrong</Heading>
      <Text size='small' as='div'>
        {(__DEV__ && message) ||
          `We weren't able to load the content requested`}
        <br />
        <br />
        <Button onClick={handleReload}>Try again</Button>
      </Text>
    </Wrapper>
  );
};

export default ErrorMessage;
