import { useEffect, useRef } from 'react';

type Callback = () => void;

/** Hook representation of setInterval, will clear when the component un-mounts */
export function useInterval(callback: Callback, delay: number) {
  const savedCallback = useRef<Callback>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const interval = setInterval(savedCallback.current, delay);
    return () => clearInterval(interval);
  }, [delay]);
}
