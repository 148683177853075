import Button from 'components/button';
import Heading from 'components/heading';
import Icon from 'components/icon';
import MetaTags from 'components/meta-tags';
import NumberCircle from 'components/number-circle';
import { useInterval } from 'hooks/use-interval';
import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SETTINGS } from 'settings/config';
import { VideoMetaTags } from 'types/interfaces';
import {
  AutoplayWrapper,
  Container,
  MobileAutoPlay,
  StyledNextVideo,
  Thumbnail,
  ThumbnailWrapper,
} from './next-video.styled';

export type NextVideo = {
  videoTags: VideoMetaTags;
  videoId: string;
  videoTitle: string;
  thumbnail: string;
  autoplay?: boolean;
  isHelpOpen?: boolean;
  onAutoplay?: () => void;
  isSmallScreen?: boolean;
};

const NextVideo: FC<NextVideo> = ({
  videoTags,
  videoId,
  videoTitle,
  thumbnail,
  isHelpOpen = false,
  autoplay,
  onAutoplay,
  isSmallScreen = false,
}) => {
  const history = useHistory();
  const [count, setCount] = useState(SETTINGS.autoplayTimeout);
  const nextVideoRoute = `/watch/${videoId}`;

  // Count down for autoplay
  useInterval(() => {
    setCount((count) => {
      if (count > 0) {
        return count - 1;
      }
      return 0;
    });
  }, 1000);

  // Navigate to next video when autoplay is enabled
  useEffect(() => {
    if (autoplay && count === 0 && !isHelpOpen) {
      onAutoplay && onAutoplay();
      history.push(nextVideoRoute + history.location.search);
    }
  }, [autoplay, count, history, nextVideoRoute, isHelpOpen, onAutoplay]);

  return (
    <StyledNextVideo>
      <Heading type='h4' mb={isSmallScreen ? 2 : 4}>
        NEXT VIDEO
      </Heading>
      <Container>
        <div>
          <ThumbnailWrapper>
            <Thumbnail src={thumbnail} alt={`${videoTitle} thumbnail`} />
            {autoplay && isSmallScreen && (
              <MobileAutoPlay>
                <NumberCircle
                  count={count}
                  duration={SETTINGS.autoplayTimeout}
                ></NumberCircle>
              </MobileAutoPlay>
            )}
          </ThumbnailWrapper>
          {!isSmallScreen && <MetaTags tags={videoTags}></MetaTags>}
        </div>
        <div>
          <Heading type='h3' mt={isSmallScreen ? 2 : 3}>
            {videoTitle}
          </Heading>
          <AutoplayWrapper>
            <Button as={Link} to={nextVideoRoute}>
              <Icon type='plyr-play' /> Play Next Video
            </Button>
            {autoplay && !isSmallScreen && (
              <>
                <NumberCircle
                  count={count}
                  duration={SETTINGS.autoplayTimeout}
                  size='large'
                ></NumberCircle>
              </>
            )}
          </AutoplayWrapper>
        </div>
      </Container>
    </StyledNextVideo>
  );
};

NextVideo.defaultProps = {
  autoplay: false,
};

export default NextVideo;
