import React, { FC, forwardRef } from 'react';
import { fontSize } from 'settings/styles';
import { SpaceProps } from 'styled-system';
import { StyledText } from './text.styled';

export type TextProps = {
  size?: keyof typeof fontSize;
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
} & SpaceProps;

const Text: FC<TextProps> = forwardRef(
  ({ size = 'medium', as, ...props }, ref) => {
    return <StyledText size={size} as={as} ref={ref} {...props} />;
  }
);

export default Text;
