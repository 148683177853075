import { AnimatePresence } from 'framer-motion';
import React, { FC, useEffect, useState } from 'react';
import { Logo, LogoWrapper, Wrapper } from './loader.styled';

export type Loader = {
  isVisible?: boolean;
};

const Loader: FC<Loader> = ({ isVisible: visible }) => {
  const [isVisible, setIsVisible] = useState(visible);

  // Add a 1s delay to changes in the visibility prop to give the animation a chance to display
  useEffect(() => {
    const delayTimer = window.setTimeout(() => {
      setIsVisible(visible);
    }, 1000);

    return () => {
      clearTimeout(delayTimer);
    };
  }, [visible]);

  return (
    <AnimatePresence>
      {isVisible && (
        <Wrapper>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

Loader.defaultProps = {
  isVisible: false,
};

export default Loader;
