import icons from 'icons/plyr.svg';
import Plyr, { Options, PlyrEvent } from 'plyr';
import 'plyr/dist/plyr.css';
import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { SETTINGS } from 'settings/config';
import { CustomHtmlVideoElement, VideoCaption } from 'types/interfaces';
import { browser, getDeviceOrientation } from 'utils/helpers';
import { OverlayControl, StyledVideo } from './video.styled';

export type Video = {
  src: string;
  isSmallScreen?: boolean;
  plyrInstance?: React.MutableRefObject<Plyr | undefined>;
  captions?: VideoCaption;
  options?: Options;
  hideOverlayToggle?: boolean;
  inMobilePortrait?: boolean;
  onStart?: (event: PlyrEvent) => void;
  onPlay?: (event: PlyrEvent) => void;
  onPause?: (event: PlyrEvent) => void;
  onEnd?: (event: PlyrEvent) => void;
  onProgress?: (progress: number) => void;
  onControlVisibilityChange?: (isVisible: boolean) => void;
};

const defaultOptions: Options = {
  iconUrl: icons,
  autoplay: true,
  hideControls: true,
  muted: false,
  fullscreen: { iosNative: true },
  controls: ['play-large', 'play', 'progress', 'current-time', 'captions'],
};

const Video: FC<Video> = memo(
  ({
    src,
    isSmallScreen = false,
    plyrInstance,
    captions,
    options,
    hideOverlayToggle = true,
    inMobilePortrait = false,
    onPlay,
    onPause,
    onStart,
    onEnd,
    onProgress,
    onControlVisibilityChange,
  }) => {
    const isDestroyed = useRef(false);
    const playerRef = useRef<Plyr>();
    const prevProgressRef = useRef(0);
    const [hideBigPlayButton, setHideBigPlayButton] = useState(false);

    const togglePlayPause = useCallback(() => {
      if (playerRef && playerRef.current?.paused) {
        playerRef.current.play();
      } else {
        playerRef.current?.pause();
      }
    }, []);

    // Check/update onProgress
    const checkProgress = useCallback(() => {
      if (playerRef.current && !isDestroyed.current) {
        const { currentTime, duration } = playerRef.current;
        const currentProgress = Math.round((currentTime / duration) * 100);

        // Which intervals(% of total time) progress events should fire at
        const intervals = [25, 50, 75, 100];

        if (prevProgressRef.current !== currentProgress) {
          // Update progress
          if (intervals.includes(currentProgress) && onProgress) {
            onProgress(currentProgress);
          }
          prevProgressRef.current = currentProgress;
        }
      }
    }, [onProgress]);

    // Check show/hide controls
    const toggleControlsView = useCallback(
      (isShown: boolean) => {
        onControlVisibilityChange && onControlVisibilityChange(isShown);
      },
      [onControlVisibilityChange]
    );

    // Init the player
    const initPlayer = useCallback(
      (el: CustomHtmlVideoElement) => {
        if (!playerRef.current) {
          // If on small screen show the fullscreen option
          if (options && isSmallScreen) {
            (options.controls as string[]).push('fullscreen');
            options.autoplay = !browser.isIos;
          }
          playerRef.current = new Plyr(el, options);

          // Hide the controls immediately if auto-playing
          if (options?.autoplay) {
            playerRef.current.toggleControls(false);
          }

          // Set the plyr instance prop if provided
          if (plyrInstance) {
            plyrInstance.current = playerRef.current;
          }

          // Events
          playerRef.current.on('play', (event) => {
            if (
              isSmallScreen &&
              (el.webkitSupportsFullscreen || el.fullscreenEnabled) &&
              (!el.webkitDisplayingFullscreen || !el.fullscreenElement) &&
              getDeviceOrientation().indexOf('landscape') >= 0
            ) {
              browser.isIos
                ? el.webkitEnterFullscreen()
                : el.requestFullscreen();
            }
            onPlay?.(event);
          });
          playerRef.current.on('pause', (event) => {
            setHideBigPlayButton(false);
            onPause?.(event);
          });
          playerRef.current.on('playing', (event) => {
            if (
              isSmallScreen &&
              (el.webkitSupportsFullscreen || el.fullscreenEnabled) &&
              (!el.webkitDisplayingFullscreen || !el.fullscreenElement) &&
              getDeviceOrientation().indexOf('landscape') >= 0
            ) {
              browser.isIos
                ? el.webkitEnterFullscreen()
                : el.requestFullscreen();
            }
            onStart?.(event);
          });
          playerRef.current.on('ended', (event) => {
            if (
              isSmallScreen &&
              el.webkitSupportsFullscreen &&
              (el.webkitDisplayingFullscreen || el.fullscreenElement)
            ) {
              browser.isIos ? el.webkitExitFullscreen() : el.exitFullscreen();
            }
            setHideBigPlayButton(true);
            onEnd?.(event);
          });
          // // The time indicated by the element's currentTime attribute has changed.
          // // Can be used to detect when video is also complete
          playerRef.current.on('timeupdate', checkProgress);
          // // video controls are shown or hidden
          playerRef.current.on('controlsshown', () => toggleControlsView(true));
          playerRef.current.on('controlshidden', () =>
            toggleControlsView(false)
          );
        }
      },
      [
        options,
        isSmallScreen,
        plyrInstance,
        checkProgress,
        onPlay,
        onPause,
        onStart,
        onEnd,
        toggleControlsView,
      ]
    );

    // Destroy the plyr instance when un-mounted
    useEffect(() => {
      return () => {
        playerRef.current?.destroy();
        isDestroyed.current = true;
      };
    }, []);

    // timer to show controls at start of video
    useEffect(() => {
      const controlsTimer = window.setTimeout(() => {
        plyrInstance?.current?.toggleControls(true);
      }, 500);

      return () => {
        clearTimeout(controlsTimer);
      };
    }, [plyrInstance]);

    // timer to hide controls after three seconds
    useEffect(() => {
      const hideControlsTimer = window.setTimeout(() => {
        plyrInstance?.current?.toggleControls(false);
      }, 3500);

      return () => {
        clearTimeout(hideControlsTimer);
      };
    }, [plyrInstance]);

    useEffect(() => {
      if (inMobilePortrait && playerRef.current?.fullscreen.active) {
        playerRef.current?.fullscreen.exit();
      }
    }, [inMobilePortrait]);

    return (
      <StyledVideo hideBigButton={hideBigPlayButton}>
        {SETTINGS.tapToPause && (
          <OverlayControl
            hideOverlay={hideOverlayToggle}
            onClick={togglePlayPause}
          ></OverlayControl>
        )}
        <video src={src} controls playsInline ref={initPlayer}>
          {captions && (
            <track
              kind={captions.kind}
              label={captions.label}
              default
              key={captions.lang}
              src={captions.src}
              srcLang={captions.lang}
            ></track>
          )}
        </video>
      </StyledVideo>
    );
  }
);

Video.defaultProps = {
  options: defaultOptions,
};

export default Video;
