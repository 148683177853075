import Text from 'components/text';
import React, { FC } from 'react';
import { VideoMetaTags } from 'types/interfaces';

export type MetaTagsProps = {
  tags: VideoMetaTags;
};

const MetaTags: FC<MetaTagsProps> = ({ tags }) => {
  return (
    <div>
      {tags.ageRestriction && (
        <Text size='small' as='span' mr={3}>
          {tags.ageRestriction}
        </Text>
      )}
      <Text size='small' as='span' mr={3}>
        {!!tags.captions.src ? 'Captioned' : 'Not captioned'}
      </Text>
      {tags.duration && (
        <Text size='small' as='span'>
          {tags.duration}
        </Text>
      )}
    </div>
  );
};

export default MetaTags;
