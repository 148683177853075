export const colors = {
  white: '#fff',
  black: '#000',
  yellow: '#f3c300',

  get text() {
    return this.white;
  },
  get background() {
    return this.black;
  },
};
