import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const headingShared = css<SpaceProps>`
  margin: 0;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.05;
  transition: color ${({ theme }) => theme.transitions.ease};
  ${space};
`;

export const H1 = styled.h1`
  ${headingShared}

  font-size: ${({ theme }) => theme.fontSize.large};

  ${({ theme }) => theme.mediaQueries.large} {
    font-size: ${({ theme }) => theme.fontSize.xLarge};
  }

  ${({ theme }) => theme.mediaQueries.xxLarge} {
    font-size: ${({ theme }) => theme.fontSize.xxLarge};
  }
`;

export const H2 = styled.h2`
  ${headingShared}

  font-size: ${({ theme }) => theme.fontSize.xLarge};
`;

export const H3 = styled.h3`
  ${headingShared}

  font-size: ${({ theme }) => theme.fontSize.medium};

  ${({ theme }) => theme.mediaQueries.large} {
    font-size: ${({ theme }) => theme.fontSize.large};
  }
`;

export const H4 = styled.h4`
  ${headingShared}

  font-size: ${({ theme }) => theme.fontSize.xSmall};

  ${({ theme }) => theme.mediaQueries.large} {
    font-size: ${({ theme }) => theme.fontSize.medium};
  }
`;
